@import '~bootstrap/scss/functions';
@import './functions-override';

// Dynamic Colors
$primary: var(--primary);
$secondary: var(--secondary);
$accent: var(--accent);

// Set colors
$dark-gray: #777;
$light-gray: #aaa;
$tint: #fafafa;

$custom-colors: (
    "dark-gray": $dark-gray,
    "light-gray": $light-gray,
    "tint": $tint
);

@import "~bootstrap/scss/variables";

$theme-colors: map-merge($theme-colors, $custom-colors);